import React from "react";
import ALink from "~/components/features/custom-link";
import ProductCollection from "./product-collection";

const BestSellers = ({ bestSellers }) => {
  return (
    <>
      <div className="section-padding mobile-padding pb-0">
        <h2 className="home-subtitle mb-0">BEST SELLERS</h2>
        <section className="container pt-0 mt-0 text-center new-collection mobile-padding">
          <div className="mt-4 mb-2 bestseller-link">
            <ALink href="/collections/best-seller" className="">
              View All
            </ALink>
          </div>
          {bestSellers && (
            <ProductCollection
              products={bestSellers}
              adClass="bg-white pt-0"
              priority
            />
          )}
        </section>
      </div>
    </>
  );
};

export default BestSellers;
