import dynamic from "next/dynamic";

import NextHead from "~/components/common/next-head";
import BestSellers from "~/components/partials/home/bestsellers";
import IntroSection from "~/components/partials/home/intro-section";
import useWindowDimensions from "~/utils/getWindowDimension";

const CustomText = dynamic(() =>
  import("~/components/partials/home/custom-text")
);
const ShopByConcerns = dynamic(() =>
  import("~/components/partials/home/shop-by-concerns")
);
const Blogs = dynamic(() => import("~/components/partials/home/blogs"));
const HomeContent = dynamic(() =>
  import("~/components/partials/home/home-content")
);

const Testimonials = dynamic(() =>
  import("~/components/partials/home/testimonial")
);

export { getStaticProps } from "~/utils/page";

function HomePage({ hero, bestSellerProducts, store, pageMeta }) {
  const { name } = store || {};
  const { isSmallSize } = useWindowDimensions();

  return (
    <main className="main home searchBar">
      <NextHead {...pageMeta} />

      <h1 className="d-none">{name} - Homepage</h1>
      <div className="bg-white">
        <div className="page-content page-content-wrapper">
          <div className="intro-section">
            <IntroSection {...hero} />
          </div>
          {!isSmallSize && <CustomText />}
          <BestSellers bestSellers={bestSellerProducts} />
          <ShopByConcerns />
          <Blogs />
          <HomeContent />
          <Testimonials />
        </div>
      </div>
    </main>
  );
}

HomePage.showStickyCheckout = true;
HomePage.showTopRunner = true;
HomePage.showTimer = true;
export default HomePage;
