export const mainSlider1 = {
  items: 5,
  nav: false,
  loop: false,
  dots: true,
  margin: 2,
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
    },
    1200: {
      items: 5,
      dots: false,
    },
  },
};

export const mainSlider2 = {
  items: 1,
  loop: false,
  nav: true,
  dots: true,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 1,
    },
  },
};

export const mainSlider3 = {
  autoHeight: false,
  dots: true,
  nav: true,
};

export const mainSlider4 = {
  nav: false,
  dots: true,
  items: 1,
  margin: 20,
  loop: false,
  autoPlay: true,
};

export const mainSlider5 = {
  nav: false,
  dots: true,
  items: 4,
  margin: 20,
  loop: false,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
      dots: false,
    },
  },
};

export const mainSlider6 = {
  nav: false,
  dots: true,
  items: 3,
  margin: 20,
  loop: false,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    992: {
      items: 3,
      dots: false,
    },
  },
};

export const mainSlider7 = {
  items: 1,
  nav: true,
  dots: false,
  autoPlay: true,
  loop: false,
  margin: 20,
};

export const mainSlider8 = {
  items: 6,
  nav: false,
  dots: false,
  margin: 0,
  autoplay: true,
  responsive: {
    0: {
      items: 2,
    },
    576: {
      items: 3,
    },
    768: {
      items: 4,
    },
    992: {
      items: 5,
    },
    1200: {
      items: 6,
    },
  },
};

export const mainSlider9 = {
  items: 5,
  nav: false,
  dots: true,
  margin: 20,
  autoplay: true,
  responsive: {
    0: {
      items: 2,
    },
    576: {
      items: 3,
    },
    992: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};

export const mainSlider10 = {
  items: 1,
  margin: 20,
  loop: false,
  nav: false,
  dots: true,
  responsive: {
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
  },
};

export const mainSlider11 = {
  nav: false,
  dots: true,
  loop: false,
  margin: 20,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
      dots: false,
    },
  },
};

export const mainSlider12 = {
  nav: false,
  dots: true,
  loop: false,
  margin: 20,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
      dots: false,
    },
  },
};

export const mainSlider13 = {
  items: 3,
  margin: 20,
  loop: false,
  nav: false,
  dots: true,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 3,
      dots: false,
    },
  },
};

export const mainSlider14 = {
  items: 2,
  nav: false,
  dots: true,
  loop: false,
  margin: 20,
  responsive: {
    0: {
      items: 1,
    },
    992: {
      items: 2,
      dots: false,
    },
  },
};

export const mainSlider15 = {
  items: 0,
  dots: true,
  loop: false,
  nav: false,
  autoHeight: false,
};

export const mainSlider16 = {
  items: 7,
  nav: false,
  dots: false,
  margin: 20,
  autoplay: true,
  autoplayTimeout: 3000,
  loop: true,
  responsive: {
    0: {
      items: 2,
    },
    576: {
      items: 3,
    },
    768: {
      items: 4,
    },
    992: {
      items: 5,
    },
    1200: {
      items: 6,
    },
  },
};

export const mainSlider17 = {
  loop: false,
  // dots: true,
  nav: false,
  margin: 20,
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
      dots: false,
      nav: false,
    },
  },
  // ,
  // dotsContainer: ".product-thumbs"
};

export const mainSlider18 = {
  items: 3,
  loop: false,
  nav: true,
  dots: false,
  margin: 20,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
  },
};

export const mainSlider19 = {
  nav: false,
  loop: false,
  dots: false,
  autoPlay: false,
  margin: 20,
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
    },
  },
};

export const mainSlider20 = {
  nav: false,
  dots: true,
};

// home page
export const introSlider = {
  nav: false,
  dots: true,
  loop: true,
  items: 1,
  autoplay: true,
  autoplayTimeout: 5000,
};

export const announcementSlider = {
  nav: false,
  dots: false,
  loop: true,
  items: 1,
  autoplay: true,
  autoplayTimeout: 5000,
};

export const serviceSlider = {
  items: 3,
  nav: false,
  dots: false,
  margin: 0,
  loop: false,
  autoplay: false,
  autoplayTimeout: 5000,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
      loop: false,
    },
  },
};

export const brandSlider = {
  items: 6,
  nav: false,
  dots: false,
  autoplay: true,
  loop: false,
  margin: 20,
  responsive: {
    0: {
      items: 2,
    },
    576: {
      items: 3,
    },
    768: {
      items: 4,
    },
    992: {
      items: 5,
    },
    1200: {
      items: 6,
    },
  },
};

export const productSlider = {
  items: 5,
  nav: false,
  dots: true,
  autoplay: false,
  loop: false,
  margin: 10,
  responsive: {
    0: {
      items: 2,
      dots: false,
    },
    768: {
      items: 3,
    },
    992: {
      items: 3,
    },
  },
};

export const productSliderLarge = {
  items: 5,
  nav: false,
  dots: true,
  autoplay: false,
  loop: false,
  margin: 10,
  responsive: {
    0: {
      items: 1,
      stagePadding: 30,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
    },
  },
};

export const categorySlider = {
  items: 5,
  nav: false,
  dots: false,
  autoplay: false,
  loop: false,
  margin: 20,
  responsive: {
    0: {
      items: 4.5,
    },
  },
};

export const productSlider2 = {
  items: 5,
  nav: false,
  dots: true,
  autoplay: false,
  loop: false,
  margin: 20,
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
    },
    1200: {
      items: 5,
      dots: false,
      nav: true,
    },
  },
};

export const homePageConcernsSlider = {
  nav: false,
  margin: 10,
  responsive: {
    0: {
      items: 1,
      stagePadding: 30,
    },
    450: {
      items: 2,
      stagePadding: 30,
    },
    768: {
      items: 3,
    },
  },
};

export const testimonialsSlider = {
  margin: 15,
  nav: true,

  navText: [
    '<div class="nav-btn prev-slide"><svg class="flickity-button-icon" width="16" height="16" viewBox="0 0 100 100"><title>Previous</title><path d="M 10, 50 L 60, 100 L 65, 95 L 20, 50 L 65, 5 L 60, 0 Z" class="arrow" fill="white"></path></svg></div>',
    '<div class="nav-btn next-slide"><svg class="flickity-button-icon" width="16" height="16" viewBox="0 0 100 100"><title>Next</title><path d="M 10, 50 L 60, 100 L 65, 95 L 20, 50 L 65, 5 L 60, 0 Z" class="arrow" transform="translate(100, 100) rotate(180)" fill="white"></path></svg></div>',
  ],
  responsive: {
    0: {
      items: 1,
      stagePadding: 25,
    },
    768: {
      items: 2,
    },
    1099: {
      items: 3,
    },
  },
};
