import React, { useEffect } from "react";
import Reveal from "react-awesome-reveal";

import ALink from "~/components/features/custom-link";
import OwlCarousel from "~/components/features/owl-carousel";
import ProductTwo from "~/components/features/product/product-two";
import { productSlider, productSliderLarge } from "~/utils/data/carousel";
import { fadeIn } from "~/utils/data/keyframes";

function ProductCollection({
  products = [],
  title = "",
  slug,
  redirectTo,
  disableCarousel,
  large,
  adClass,
  priority,
}) {
  useEffect(() => {
    const ele = document.getElementById(`product-carousel-${slug}`);
    if (ele) {
      if (disableCarousel) {
        ele.classList.remove("owl-carousel");
        ele.classList.add("remove-carousel");
      } else {
        ele.classList.add("owl-carousel");
        ele.classList.remove("remove-carousel");
      }
    }
  }, [disableCarousel]);

  return (
    <Reveal
      keyframes={fadeIn}
      delay={300}
      duration={1200}
      triggerOnce
      className={`product-widget-wrapper ${adClass}`}
    >
      <section
        className={`product-wrapper product-collection container pb-3 ${adClass}`}
      >
        <div className="d-flex justify-content-between collection-title mb-4">
          {!!redirectTo && (
            <ALink href={redirectTo}>
              <p className="view-all  text-underline m-0">VIEW ALL</p>
            </ALink>
          )}
        </div>

        <OwlCarousel
          id={`product-carousel-${slug}`}
          adClass="owl-theme owl-nav-full"
          options={!large ? productSlider : productSliderLarge}
        >
          {products?.map((item, index) => (
            <ProductTwo
              adClass="mb-4 text-center"
              slug={slug}
              product={item}
              key={`top-selling-product-${item.id}`}
              section={{
                id: title.toLowerCase().replace(/\ /g, "-"),
                name: title,
              }}
              priority={!!(priority && index < 4)}
            />
          ))}
        </OwlCarousel>
      </section>
    </Reveal>
  );
}

export default React.memo(ProductCollection);
