import React, { useEffect, useState } from "react";
import Image from "~/components/image";

import { connect } from "react-redux";
import ALink from "~/components/features/custom-link";
import OwlCarousel from "~/components/features/owl-carousel";
import { eventActions } from "~/store/events";
import { introSlider } from "~/utils/data/carousel";
import { getSource } from "~/utils/helper";

const MOBILE_BREAKPOINT = 575;

function IntroSection({ banners = [], bannerClicked, homeViewed }) {
  useEffect(() => {
    homeViewed();
  }, []);
  const source = getSource();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filteredBanners = banners.filter((banner) => !banner.isArchive);
  filteredBanners.sort((a, b) => a.priority - b.priority);
  const isCarousel = filteredBanners.length > 1;

  return (
    <div className="banner banner-fixed">
      {isCarousel ? (
        <OwlCarousel
          adClass="owl-theme owl-dot-inner owl-dot-white intro-slider animation-slider intro-slider-container special-case"
          options={introSlider}
        >
          {filteredBanners.map((banner, index) => {
            const { webKey, mobileKey, link } = banner;
            return (
              <div className="intro-slide2" key={webKey}>
                <ALink
                  href={link || "#"}
                  onClick={() => {
                    bannerClicked({
                      Source: source,
                      item_id: index,
                      banner_name: webKey,
                    });
                  }}
                  className={`d-sm-none intro-slider-link`} //for desktop
                >
                  <Image
                    src={webKey}
                    alt="WOW"
                    priority={!index}
                    quality={90}
                    width={1920}
                    height={512}
                    objectFit="cover"
                  />
                </ALink>
                <ALink
                  href={link || "#"}
                  className="d-none d-sm-show intro-slider-link" //for mobile
                >
                  <Image
                    src={mobileKey}
                    alt="WOW"
                    priority={!index}
                    quality={95}
                    width={575}
                    height={350}
                    objectFit="cover"
                  />
                </ALink>
              </div>
            );
          })}
        </OwlCarousel>
      ) : (
        <ALink
          href={filteredBanners[0]?.link || "#"}
          onClick={() => {
            bannerClicked({
              Source: source,
              item_id: 0,
              banner_name: filteredBanners[0]?.webKey,
            });
          }}
          className={`intro-slider-link`}
        >
          {filteredBanners?.length > 0 && (
            <>
              {isMobile ? (
                <Image
                  src={filteredBanners[0]?.mobileKey}
                  alt="WOW"
                  priority={true}
                  quality={95}
                  width={575}
                  height={350}
                  objectFit="cover"
                />
              ) : (
                <Image
                  src={filteredBanners[0]?.webKey}
                  alt="WOW"
                  priority={true}
                  quality={90}
                  width={1920}
                  height={512}
                  objectFit="cover"
                />
              )}
            </>
          )}
        </ALink>
      )}
    </div>
  );
}

export default connect(null, {
  bannerClicked: eventActions.bannerClicked,
  homeViewed: eventActions.homeViewed,
})(React.memo(IntroSection));
